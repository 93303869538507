// OccupancyDaily.jsx

import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";

// Utility functions
const formatCurrency = (value) => {
    return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

const formatDateToSwedish = (date) => {
    return new Intl.DateTimeFormat("sv-SE", { weekday: "long", day: "numeric", month: "long", year: "numeric" }).format(date);
};

// Calculate fill rate for impressions
const calculateFillRate = (impressions, unfilledImpressions) => {
    const totalImpressions = impressions + unfilledImpressions;
    return ((impressions / totalImpressions) * 100).toFixed(1);
};

// LargeCard component to display occupancy with a list sorted by revenue and direct revenue ratio
const LargeCard = ({ topRevenue, topDirectRevenueRatio }) => {
    const currentDate = formatDateToSwedish(new Date());
    const yesterdayDateYYYYMMDD = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];
    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Gårdagens intäkter webbplats - {yesterdayDateYYYYMMDD}
                    </Typography>
                    
                </Grid>
                <Grid item xs={6} mt={2} sx={{ pr: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Intäkter
                    </Typography>
                    <Grid container spacing={1}>
                        {topRevenue.map((item, index) => (
                            <Grid
                            item
                            xs={12}
                            key={index}
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                mb: 0.5,
                                backgroundColor: "#333333",
                                borderRadius: "4px",
                                p: 0.5,
                            }}
                        >
                            <Grid item xs={9}>
                                <Typography variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }} noWrap>
                                    {item.site}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "right" }}>
                                <Typography variant="body2">{formatCurrency(item.total_revenue)}</Typography>
                            </Grid>
                        </Grid>
                        
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={6} mt={2} sx={{ pl: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Utförsäljningsgrad
                    </Typography>
                    <Grid container spacing={1}>
                        {topDirectRevenueRatio.map((item, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                container
                                alignItems="center"
                                sx={{
                                    mb: 0.5,
                                    backgroundColor: "#333333",
                                    borderRadius: "4px",
                                    p: 0.5,
                                }}
                            >
                                <Grid item xs={6}>
                                    <Typography variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }} noWrap>
                                        {item.site}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2">{formatCurrency(item.total_revenue)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2">{((item.total_direct_revenue / item.total_revenue) * 100).toFixed(2)}%</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

// Main component for OccupancyDaily view
const OccupancyDaily = ({ data }) => {
    // Sort data by revenue and direct revenue ratio
    const sortedByRevenue = [...data].sort((a, b) => b.total_revenue - a.total_revenue).slice(0, 10);
    const sortedByDirectRevenueRatio = [...data]
        .sort((a, b) => (b.total_direct_revenue / b.total_revenue) - (a.total_direct_revenue / a.total_revenue))
        .slice(0, 10);

    return (
        <Box p={4}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={8}>
                    <LargeCard topRevenue={sortedByRevenue} topDirectRevenueRatio={sortedByDirectRevenueRatio} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default OccupancyDaily;
