import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

// Import Swiper styles and modules
import "swiper/css";
import "swiper/css/autoplay";

import DashboardDaily from "./DashboardDaily";
import DashboardMonth from "./DashboardMonth";
import DashboardYear from "./DashboardYear";
import OccupancyDaily from "./DashboardOccupancyDaily";
import TopPlacementsView from "./DashboardTopPlacementsView";

// Colors for each subtype
export const ntmtypecolors = {
    ADX: "#4caf50", // Green
    DIREKT: "#2196f3", // Blue
    LW: "#ff9800", // Orange
};

const Dashboard = () => {
    const [data, setData] = useState({ daily: null, month: null, year: null });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch daily data
                const dailyResponse = await fetch("https://nadir.ntm.digital/dashboard/revenue/yesterday");
                if (!dailyResponse.ok) throw new Error("Failed to load daily data");
                const dailyData = await dailyResponse.json();

                // Fetch monthly data
                const monthResponse = await fetch("https://nadir.ntm.digital/dashboard/revenue/month");
                if (!monthResponse.ok) throw new Error("Failed to load monthly data");
                const monthData = await monthResponse.json();

                // Fetch yearly data
                const yearResponse = await fetch("https://nadir.ntm.digital/dashboard/revenue/year");
                if (!yearResponse.ok) throw new Error("Failed to load yearly data");
                const yearData = await yearResponse.json();
                
                // Fetch yearly data
                const occupancyYesterdayResponse = await fetch("https://nadir.ntm.digital/dashboard/revenue/yesterday-site");
                if (!occupancyYesterdayResponse.ok) throw new Error("Failed to load yearly data");
                const occupancyYesterday = await occupancyYesterdayResponse.json();
                // Fetch yearly data
                const viewabilityRes = await fetch("https://nadir.ntm.digital/dashboard/viewability/yesterday");
                if (!viewabilityRes.ok) throw new Error("Failed to load yearly data");
                const viewabilityYesterday = await viewabilityRes.json();

                // Update data state with fetched data
                setData({ daily: dailyData, month: monthData, year: yearData, occupancy: occupancyYesterday, viewability: viewabilityYesterday });
            } catch (err) {
                setError("Failed to load data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading)
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );

    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Swiper
            modules={[Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            autoplay={{
                delay: 15000, // 15 seconds in milliseconds
                disableOnInteraction: false, // Continue autoplay after user interaction
                pauseOnMouseEnter: false, // Optional: pause on mouse hover
            }}
        >
            <SwiperSlide>
                {data.occupancy ? <OccupancyDaily data={data.occupancy} /> : <Typography>No daily data available</Typography>}
            </SwiperSlide>
            <SwiperSlide>
                {data.daily ? <DashboardDaily data={data.daily} /> : <Typography>No daily data available</Typography>}
            </SwiperSlide>
            <SwiperSlide>
                {data.month ? <DashboardMonth data={data.month} /> : <Typography>No monthly data available</Typography>}
            </SwiperSlide>
            <SwiperSlide>
                {data.year ? <DashboardYear data={data.year} /> : <Typography>No yearly data available</Typography>}
            </SwiperSlide>
            <SwiperSlide>
                {data.viewability ? <TopPlacementsView data={data.viewability} /> : <Typography>No viewability data available</Typography>}
            </SwiperSlide>
        </Swiper>
    );
};

export default Dashboard;
