export const layout = [
    { id: "start", text: "Start", route: "/", public: false },
    {
        id: "campaigns",
        text: "Kampanjer",
        route: "/kampanjer",
        public: false,
        description: "Ger dig en översikt av alla kampanjer som snart ska starta, redan är igång eller nyligen har avslutats",
    },
    // { id: "customers", text: 'Kunder', route: '/kunder', public: false, description: "Ger dig en översikt av alla kunder" },
    {
        id: "forecast",
        text: "Lagerkoll",
        description: "Kontrollera lagret i våra adservers",
        route: "/lagerkoll",
        public: true,
        subRoutes: [
            {
                id: "standardForecast",
                text: "Standard",
                route: "/lagerkoll/standard",
                public: true,
                adserver: "GAM",
                group: "Display",
                description: "Kontrollera lagret för en specifik kampanjperiod",
            },
            // {
            //     id: "siteForecast",
            //     text: 'Sajtöversikt',
            //     route: '/lagerkoll/sajt',
            //     public: true,
            //     adserver: 'GAM',
            //     group: 'Display',
            //     description: 'När du vill få en fullständig koll på en sajts produkter den närmaste tiden',
            // },
            {
                id: "productForecast",
                text: "Produktöversikt",
                route: "/lagerkoll/produkt",
                public: true,
                adserver: "GAM",
                group: "Display",
                description: "Ger dig en samlad bild över bokningsläget för en viss produkt på samtliga sajter",
            },
            {
                id: "doohForecast",
                text: "Översikt",
                route: "/lagerkoll/dooh",
                public: true,
                adserver: "DOOH",
                group: "DOOH",
                description: "Visar tillgängligt utrymme på våra digitala utomhusskyltar",
            },
        ],
    },
    {
        id: "tools",
        text: "Verktyg",
        description: "Olika verktyg för att förenkla annonsaffären",
        route: "/verktyg",
        public: true,
        subRoutes: [
            {
                id: "toolDooh",
                text: "Dooh-kalkylator",
                route: "/verktyg/dooh",
                public: true,
                description: "Verktyg för att beräkna antalet kontakter och kostnaden för att visa en annons på en digital utomhus skylt (DOOH)",
            },
            {
                id: "toolCpm",
                text: "CPM-kalkylator",
                route: "/verktyg/cpm",
                public: true,
                description: "Verktyg för att enkelt beräkna CPM, budget och visningar",
            },
            {
                id: "averageStats",
                text: "Snittstatistik",
                route: "/verktyg/snittstatistik",
                public: true,
                description: "Se genomsnittlig statistik baserat på format och tidsperiod",
            },
            // {
            //     id: "aiAdGenerator",
            //     text: 'BannerAD AI',
            //     route: '/verktyg/annons-ai',
            //     public: false,
            //     description: 'Generera en annons med hjälp av AI för att visa på sajt',
            // },
            // {
            //     id: "toolBooker",
            //     text: 'DEV-Bokningshjälp',
            //     route: '/verktyg/bokningshjalp',
            //     public: true,
            //     description: 'DEV-Bokningshjälp',
            // },
        ],
    },
    {
        id: "doohInfo",
        text: "Skyltar",
        route: "/dooh/skyltar",
        public: true,
        description: "Ger dig en översikt av de skyltar som finns tillgängliga för bokning",
    },
];

export const flattenedLayout = layout.reduce((acc, curr) => {
    acc[curr.id] = curr;
    if (curr.subRoutes) {
        curr.subRoutes.forEach(subRoute => {
            acc[subRoute.id] = subRoute;
        });
    }
    return acc;
}, {});
