import React from "react";
import { Box, Typography, Grid, Paper, Stack } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ntmtypecolors } from "./Dashboard";

// Utility function for formatting currency in SEK with no decimals
const formatCurrency = (value) => {
    return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

// Utility function to get the correct icon for diff_percent
const DiffIcon = ({ diffPercent }) => {
    return diffPercent >= 0 ? <ArrowUpwardIcon fontSize="large" color="success" /> : <ArrowDownwardIcon fontSize="large" color="error" />;
};

// LargeCard component for displaying overall current and previous year's revenue
const LargeCard = ({ previous, current, diffPercent, diff }) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
            <Grid container alignItems="center">
                <Grid item xs={9}>
                    <Typography variant="h6" gutterBottom>
                    Årsintäkter
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                    Jämförelse med föregående års ackumulerade resultat för motsvarande period.

                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" mt={2}>
                    {/* Centered Icon with vertically stacked percentage and diff */}
                    <DiffIcon diffPercent={diffPercent} />
                    <Stack spacing={0.5} ml={2}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: "bold",
                                color: diffPercent >= 0 ? "green" : "red",
                                textAlign: "left"
                            }}
                        >
                            {diffPercent.toFixed(2)}%
                        </Typography>
                        <Typography variant="h6" sx={{ color: diffPercent >= 0 ? "green" : "red" }}>
                            {formatCurrency(diff)}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {currentYear}: {formatCurrency(current)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#b0b0b0", mt: 0.5 }}>
                        {previousYear}: {formatCurrency(previous)}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

// SmallCard component for displaying current and previous year revenue for each subtype
const SmallCard = ({ title, previous, current, diffPercent, diff }) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    return (
        <Paper elevation={2} sx={{ p: 2, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
           <Typography variant="h6" gutterBottom color={ntmtypecolors[title]}>
                {title}
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
                {/* Centered Icon with vertically stacked percentage and diff */}
                <DiffIcon diffPercent={diffPercent} />
                <Stack spacing={0.5} ml={1}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: "bold",
                            color: diffPercent >= 0 ? "green" : "red",
                            textAlign: "left"
                        }}
                    >
                        {diffPercent.toFixed(2)}%
                    </Typography>
                    <Typography variant="body1" sx={{  color: diffPercent >= 0 ? "green" : "red" }}>
                        {formatCurrency(diff)}
                    </Typography>
                </Stack>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2 }}>
                {currentYear}: {formatCurrency(current)}
            </Typography>
            <Typography variant="caption" sx={{ color: "#b0b0b0", mt: 0.5 }}>
                {previousYear}: {formatCurrency(previous)}
            </Typography>
        </Paper>
    );
};

// Main component for DashboardYear view
const DashboardYear = ({ data }) => {
    const { total, ADX, DIREKT, LW } = data;

    return (
        <Box p={4}>
            <Grid container spacing={4} justifyContent="center">
                {/* LargeCard for Total Revenue */}
                <Grid item xs={12} md={8}>
                    <LargeCard
                        previous={total.previous_year}
                        current={total.current_year}
                        diffPercent={total.diff_percent}
                        diff={total.diff}
                    />
                </Grid>

                {/* SmallCards for Revenue Breakdown by Subtypes */}
                <Grid container item xs={12} md={8} spacing={2}>
                    <Grid item xs={12} md={4}>
                        
                        <SmallCard
                            title="DIREKT"
                            previous={DIREKT.previous_year}
                            current={DIREKT.current_year}
                            diffPercent={DIREKT.diff_percent}
                            diff={DIREKT.diff}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SmallCard
                            title="LW"
                            previous={LW.previous_year}
                            current={LW.current_year}
                            diffPercent={LW.diff_percent}
                            diff={LW.diff}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SmallCard
                            title="ADX"
                            previous={ADX.previous_year}
                            current={ADX.current_year}
                            diffPercent={ADX.diff_percent}
                            diff={ADX.diff}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardYear;
