// TopPlacementsView.jsx

import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";

// Utility function for formatting currency in SEK with no decimals
const formatCurrency = value => {
    return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

// LargeCard component for displaying an overview of the top placements
const LargeCard = () => {
    const yesterdayDateYYYYMMDD = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];
    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
                Gårdagens intäkter annonsplacering - {yesterdayDateYYYYMMDD}
            </Typography>
            <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                Visar data för de 6 mest intäktsgenererande annonsplaceringarna. Inkluderar visningar, intäkter, CPM, synlighet och visningstid.
            </Typography>
        </Paper>
    );
};

// SmallCard component for displaying individual placement data
const SmallCard = ({ adunit, impressions, revenue, cpm, viewability, viewtime }) => {
    return (
        <Paper elevation={2} sx={{ p: 2, backgroundColor: "#1e1e1e", color: "#ffffff", textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
                {adunit}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {formatCurrency(revenue)}
            </Typography>
            <Typography variant="body2" sx={{ color: "#b0b0b0", mt: 2 }}>
                Visningar: {impressions.toLocaleString("sv-SE")}
            </Typography>
            <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                CPM: {formatCurrency(cpm)}
            </Typography>
            <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                Synlighet: {viewability}%
            </Typography>
            <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                Visningstid: {viewtime.toFixed(1)}s
            </Typography>
        </Paper>
    );
};

// Main component for TopPlacementsView
const TopPlacementsView = ({ data }) => {
    // Sort data by revenue in descending order and select top 10
    const topPlacements = [...data].sort((a, b) => b.revenue - a.revenue).slice(0, 6);

    

    return (
        <Box p={4}>
            <Grid container spacing={4} justifyContent="center">
                {/* LargeCard for displaying overview information */}
                <Grid item xs={12} md={8}>
                    <LargeCard />
                </Grid>

                {/* SmallCards for each top placement */}
                <Grid container item xs={12} md={8} spacing={2}>
                    {topPlacements.map((placement, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <SmallCard
                                adunit={placement.adunit}
                                impressions={placement.impressions}
                                revenue={placement.revenue}
                                cpm={placement.cpm}
                                viewability={placement.viewability}
                                viewtime={placement.viewtime}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default TopPlacementsView;
