import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ntmtypecolors } from "./Dashboard";

// Utility function for formatting currency in SEK with no decimals
const formatCurrency = (value) => {
    return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

// LargeCard component for displaying overall accumulated and total revenue
const LargeCard = ({ accumulated, expected }) => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const to = yesterday.toISOString().split("T")[0];
    const from = new Date(yesterday.getFullYear(), yesterday.getMonth(), 2).toISOString().split("T")[0];
    const month = yesterday.toLocaleString('sv-SE', { month: 'long' }).charAt(0).toUpperCase() + yesterday.toLocaleString('sv-SE', { month: 'long' }).slice(1);

    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom>
                        Månadsintäkter – {month}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                        Data från {from} - {to}. Ackumulerad intäkt baseras på det genomsnittliga utfallet under de senaste 30 dagarna.
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column" alignItems="flex-start">
                    <Box display="flex" alignItems="center" mt={1}>
                        <TrendingUpIcon fontSize="small" sx={{ color: "#4caf50", mr: 1 }} />
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            {formatCurrency(accumulated)}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <HelpOutlineIcon fontSize="small" sx={{ color: "#ff9800", mr: 1 }} />
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            {formatCurrency(expected)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};


// SmallCard component for displaying accumulated and expected for each subtype
const SmallCard = ({ title, accumulated, expected }) => {
    return (
        <Paper elevation={2} sx={{ p: 2, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
            <Typography variant="h6" gutterBottom color={ntmtypecolors[title]}>
                {title}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="start" mt={1}>
                <Box display="flex" alignItems="center">
                    <TrendingUpIcon fontSize="small" sx={{ color: "#4caf50", mr: 1 }} />
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {formatCurrency(accumulated)}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                    <HelpOutlineIcon fontSize="small" sx={{ color: "#ff9800", mr: 1 }} />
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {formatCurrency(expected)}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

// Main component for DashboardMonth view
const DashboardMonth = ({ data }) => {
    const { total, ADX, DIREKT, LW } = data;

    return (
        <Box p={4}>
            <Grid container spacing={4} justifyContent="center">
                {/* LargeCard for Total Revenue */}
                <Grid item xs={12} md={8}>
                    <LargeCard accumulated={total.accumulated} expected={total.total} />
                </Grid>

                {/* SmallCards for Revenue Breakdown by Subtypes */}
                <Grid container item xs={12} md={8} spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SmallCard title="DIREKT" accumulated={DIREKT.accumulated} expected={DIREKT.total} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SmallCard title="LW" accumulated={LW.accumulated} expected={LW.total} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SmallCard title="ADX" accumulated={ADX.accumulated} expected={ADX.total} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardMonth;
